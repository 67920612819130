import wurd from 'wurd-react';
import store from 'store';
import { getLangText } from 'utils/ui';


const cms = wurd.block('common.footer');

export default function Footer() {
  const { settings } = store.get();
  return (
    <cms.Object type="footer" id="poweredby" keys="url,text">
      <div className="container d-flex justify-content-center gap-2 gap-sm-3 gap-md-4 flex-wrap mt-2">
        {settings.footerLinks?.map((link, i) => (
          <a key={i} href={getLangText(link.url)} target="_blank" className="text-muted fs-7">{getLangText(link.text)}</a>
        ))}

        <a href={cms.text('poweredby.url')} target="_blank" className="text-muted fs-7">{cms.text('poweredby.text')}</a>
      </div>
    </cms.Object>
  );
}
