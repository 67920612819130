import wurd from 'wurd-react';
import styled from '@emotion/styled';

import useForm from 'utils/useForm';
import { getLangText } from 'utils/ui';

import Button from 'components/step-button';
import Paper from 'components/paper';
import Block from 'components/block';
import Title from 'components/title';
import ErrMsg from 'components/err-msg';
import Markdown from 'components/markdown';


const cms = wurd.block('unitOrder.review');

const StyledPaper = styled(Paper)({
  'p:last-child': {
    marginBottom: 0
  }
});

export default function UnitOrderReviewStep({
  site,
  order,
  nextStep,
}) {
  const { submit, isLoading, err } = useForm({
    initialValue: order,
    cms,
    onSubmit: async function (data) {
      const errMsg = await nextStep({
        siteId: order.siteId,
        unitTypeId: order.unitTypeId,
        startDate: order.startDate,
        submitNow: true,
      });
      if (errMsg) throw new Error(errMsg);
    },
  });

  return (
    <Block as="form" onSubmit={submit}>
      <Title cms={cms.block('title')} className="mt-5" />

      {!err && (
        <StyledPaper padded className="mt-3">
          {getLangText(site.submitBookingText)
            ? <Markdown inline style={{ whiteSpace: 'pre-wrap' }}>{getLangText(site.submitBookingText)}</Markdown>
            : <cms.Markdown id="intro" />
          }
        </StyledPaper>
      )}

      {!err && <Button type="submit" loading={isLoading}><cms.Text id="submitText" /></Button>}

      {err && <ErrMsg err={err} type="warning" className="mt-2" />}
    </Block>
  )
}
